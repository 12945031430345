'use client';

import { useSession } from 'next-auth/react';
import { ComponentType, JSX } from 'react';
interface Props {
  children: JSX.Element;
  shouldBeAuthenticated?: boolean;
}
const AuthGuard: ComponentType<Props> = ({
  children,
  shouldBeAuthenticated = true
}: Props) => {
  const {
    status
  } = useSession();
  switch (true) {
    case status === 'authenticated' && shouldBeAuthenticated:
      return children;
    case status === 'unauthenticated' && !shouldBeAuthenticated:
      return children;
    default:
      return null;
  }
};
export default AuthGuard;