'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="Diamond.tsx">
    <path fillRule="evenodd" clipRule="evenodd" d="M20.9945 11.8954L13.4945 20.3291C13.115 20.7559 12.5711 21 12 21C11.4289 21 10.885 20.7559 10.5055 20.3291L3.00549 11.8954C2.43742 11.2566 2.33816 10.3274 2.75849 9.583L5.9014 4.01666C6.25609 3.38848 6.9216 2.99999 7.643 3H16.3571C17.0785 3 17.744 3.38849 18.0986 4.01665L21.2415 9.583C21.6618 10.3274 21.5626 11.2566 20.9945 11.8954V11.8954Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Diamond.tsx" />
    <path d="M8 11H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Diamond.tsx" />
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="Diamond.tsx" />;
};
export default CustomIcon;