'use client';

import { Button, Modal } from 'antd';
import Image from 'next/image';
import { usePathname, useSearchParams } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { useRouter } from 'nextjs-toploader/app';
import React, { ComponentType, useCallback, useEffect, useMemo, useState } from 'react';
import CheckCircleIcon from '@/src/shared/components/Icons/CheckCircle';
import DiamondIcon from '@/src/shared/components/Icons/Diamond';
import { RESTORATION_URL } from '@/src/shared/constants/local-storage';
import * as routes from '@/src/shared/constants/routes';
export enum UpgradeToPremiumTypeEnum {
  GenerateImages = 'generateImages',
  CreateCharacters = 'generateVideos',
  UnlimitedMessages = 'unlimitedMessages',
}
const UpgradeToPremiumModal: ComponentType = () => {
  const t = useTranslations();
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const modalType: UpgradeToPremiumTypeEnum | undefined = searchParams.get('upgradeToPremium') as UpgradeToPremiumTypeEnum ?? undefined;

  // This strange approach makes sure there is no hydration problem!
  useEffect(() => {
    setIsOpen(!!modalType);
  }, [modalType]);
  const handleClose = useCallback(() => {
    const params = new URLSearchParams(searchParams.toString());
    params.delete('upgradeToPremium');
    router.replace(`${pathname}?${params.toString()}`, {
      scroll: false
    });
  }, [pathname, router, searchParams]);
  const modalTitleKey = useMemo((): string => {
    switch (modalType) {
      case UpgradeToPremiumTypeEnum.GenerateImages:
        return 'upgradeToPremiumModal.titles.generateImages';
      case UpgradeToPremiumTypeEnum.CreateCharacters:
        return 'upgradeToPremiumModal.titles.createCharacters';
      default:
      case UpgradeToPremiumTypeEnum.UnlimitedMessages:
        return 'upgradeToPremiumModal.titles.unlimitedMessages';
    }
  }, [modalType]);
  const handlePurchaseButtonClick = useCallback(() => {
    localStorage.setItem(RESTORATION_URL, pathname);
    router.push(routes.PRICING);
  }, [pathname, router]);
  return <Modal open={isOpen} onCancel={handleClose} closable footer={null} className="!p-4 !max-w-[400px] !w-full" classNames={{
    content: '!p-4 sm:!p-6 !relative overflow-hidden !rounded-xl'
  }} centered data-sentry-element="Modal" data-sentry-component="UpgradeToPremiumModal" data-sentry-source-file="UpgradeToPremiumModal.tsx">
      <div className="relative z-10">
        <h1 className="text-2xl font-bold -mt-2">
          {t.rich(modalTitleKey, {
          span: children => <span className="text-primary-500">{children}</span>,
          br: () => <br />
        })}
        </h1>

        <p className="my-3 text-sm  text-textBase-600 leading-6">
          {t('upgradeToPremiumModal.description')}
        </p>

        <ul className="gap-2 flex flex-col mb-4">
          {t.raw('upgradeToPremiumModal.features').map((text: string, index: number) => <li className="m-0 p-0 flex items-center gap-2" key={index}>
                <div className="text-2xl text-[#FCF400] leading-none">
                  <CheckCircleIcon />
                </div>
                <p className="text-sm text-textBase-600 leading-5">{text}</p>
              </li>)}
        </ul>

        <Button icon={<DiamondIcon className="!text-2xl" />} type="primary" className="w-full" onClick={handlePurchaseButtonClick} data-sentry-element="Button" data-sentry-source-file="UpgradeToPremiumModal.tsx">
          {t('upgradeToPremiumModal.button')}
        </Button>
      </div>

      <Image className="object-cover opacity-10" src="/assets/images/abstract-wave-background.jpg" alt="abstract-wave" fill priority sizes="400px" data-sentry-element="Image" data-sentry-source-file="UpgradeToPremiumModal.tsx" />
    </Modal>;
};
export default UpgradeToPremiumModal;