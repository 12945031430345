'use client';

import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { PasswordRecoveryInterface } from '@/src/shared/components/AuthModal/PasswordRecoveryForm/types/password-recovery.interface';
import { mutationFetcherCreator } from '@/src/shared/config/queryClient';

export const usePasswordRecoveryMutation = (): UseMutationResult<
  unknown,
  Error,
  PasswordRecoveryInterface
> => {
  return useMutation({
    mutationFn: mutationFetcherCreator('/api/auth/password-recovery', 'POST'),
  });
};
