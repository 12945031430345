'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="Email.tsx">
    <path d="M10.9999 18.0029H5.99783C4.34029 18.0029 2.99658 16.6592 2.99658 15.0017V6.99832C2.99658 5.34078 4.34029 3.99707 5.99783 3.99707H18.0028C19.6604 3.99707 21.0041 5.34078 21.0041 6.99832V11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Email.tsx" />
    <path d="M20.9652 6.61323L13.5344 10.9538C12.5992 11.5002 11.4421 11.5002 10.5068 10.9538L3.0376 6.59082" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Email.tsx" />
    <path fillRule="evenodd" clipRule="evenodd" d="M17.0994 20.7287L21.2111 16.6169C21.6017 16.2263 21.6017 15.593 21.2111 15.2024L19.8027 13.7939C19.6151 13.6064 19.3607 13.501 19.0955 13.501C18.8302 13.501 18.5758 13.6064 18.3882 13.7939L14.2765 17.9057C14.1001 18.0821 14.001 18.3213 14.001 18.5707V20.504C14.001 20.6367 14.0537 20.7639 14.1475 20.8577C14.2412 20.9514 14.3684 21.0041 14.5011 21.0041H16.4344C16.6838 21.0041 16.923 20.9051 17.0994 20.7287Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Email.tsx" />
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="Email.tsx" />;
};
export default CustomIcon;