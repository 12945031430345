'use client';

import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { PRIVACY_STORE } from '@/src/shared/constants/local-storage';
import { CookieStoringCategoryEnum } from '@/src/shared/types/enums/cookie-storing-category.enum';
interface PrivacyStoreState {
  _isHydrated: boolean;
  _setIsHydrated: (state: boolean) => void;
  isPrivacyAlertModalOpen: boolean;
  setIsPrivacyAlertModalOpen: (isOpen: boolean) => void;
  isPrivacyManagementModalOpen: boolean;
  setIsPrivacyManagementModalOpen: (isOpen: boolean) => void;
  categories: Record<CookieStoringCategoryEnum, boolean> | null;
  setCategories: (categories: Record<CookieStoringCategoryEnum, boolean>) => void;
}
export const usePrivacyStore = create<PrivacyStoreState, [['zustand/persist', PrivacyStoreState]]>(persist(set => {
  return {
    _isHydrated: false,
    _setIsHydrated: (state): void => {
      set({
        _isHydrated: state
      });
    },
    isPrivacyAlertModalOpen: false,
    setIsPrivacyAlertModalOpen: (isOpen): void => {
      set(() => {
        return {
          isPrivacyAlertModalOpen: isOpen
        };
      });
    },
    isPrivacyManagementModalOpen: false,
    setIsPrivacyManagementModalOpen: (isOpen): void => {
      set(() => {
        return {
          isPrivacyManagementModalOpen: isOpen
        };
      });
    },
    // TODO: Temporary enable all categories
    categories: {
      [CookieStoringCategoryEnum.ESSENTIAL]: true,
      [CookieStoringCategoryEnum.ANALYTIC]: true,
      [CookieStoringCategoryEnum.MARKETING]: true
    },
    setCategories: (categories): void => {
      set(() => {
        return {
          categories: categories
        };
      });
    }
  };
}, {
  name: PRIVACY_STORE,
  partialize: state => {
    return {
      categories: state.categories
    } as PrivacyStoreState;
  },
  onRehydrateStorage: () => {
    return (state): void => {
      if (state) {
        state._setIsHydrated(true);
      }
    };
  }
}));