import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Button, Input, Form } from 'antd';
import { useSearchParams } from 'next/navigation';
import { signIn } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { useRouter } from 'nextjs-toploader/app';
import { ComponentType, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PasswordResetSchema } from '@/src/shared/components/AuthModal/PasswordResetForm/schemas/password-reset.schema';
import LockIcon from '@/src/shared/components/Icons/Lock';
import { usePasswordResetMutation } from '@/src/shared/hooks/queries/usePasswordResetMutation';
import { useNotificationStore } from '@/src/shared/hooks/stores/useNotificationStore';
interface Props {
  onModalClose: () => void;
}
const PasswordResetForm: ComponentType<Props> = ({
  onModalClose
}) => {
  const t = useTranslations();
  const searchParams = useSearchParams();
  const router = useRouter();
  const {
    notificationApi
  } = useNotificationStore();
  const {
    mutateAsync,
    isPending
  } = usePasswordResetMutation();
  const {
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<PasswordResetSchema>({
    resolver: classValidatorResolver(PasswordResetSchema),
    mode: 'onChange'
  });
  const onFinish = useCallback(async (values: PasswordResetSchema) => {
    const token = searchParams.get('token') || '';
    const email = searchParams.get('email') || '';
    try {
      const {
        accessToken,
        refreshToken
      } = await mutateAsync({
        ...values,
        token,
        email
      });
      await signIn('OAUTH', {
        accessToken,
        refreshToken,
        redirect: false
      });
      notificationApi?.success({
        message: 'Password successfully updated',
        description: 'You are already signed in',
        duration: 6
      });
      onModalClose();
      router.refresh();
    } catch (e) {
      const error = e as Error;
      notificationApi?.error({
        message: error.name,
        description: error?.message
      });
    }
  }, [router, notificationApi, mutateAsync, searchParams, onModalClose]);
  return <form onSubmit={handleSubmit(onFinish)} data-sentry-component="PasswordResetForm" data-sentry-source-file="PasswordResetForm.tsx">
      <h1 className="text-xl font-medium mb-3">
        {t('passwordResetForm.title')}
      </h1>
      <div className="flex flex-col gap-2 mb-5">
        <Controller render={({
        field
      }) => {
        return <Form.Item validateStatus={errors.password ? 'error' : undefined} help={errors.password?.message}>
                <Input.Password size="large" placeholder={t('common.password')} visibilityToggle prefix={<LockIcon className="text-xl" />} disabled={isPending} {...field} />
              </Form.Item>;
      }} name="password" control={control} data-sentry-element="Controller" data-sentry-source-file="PasswordResetForm.tsx" />

        <Controller render={({
        field
      }) => {
        return <Form.Item validateStatus={errors.confirmPassword ? 'error' : undefined} help={errors.confirmPassword?.message}>
                <Input.Password size="large" placeholder={t('common.confirmPassword')} visibilityToggle prefix={<LockIcon className="text-xl" />} disabled={isPending} {...field} />
              </Form.Item>;
      }} name="confirmPassword" control={control} data-sentry-element="Controller" data-sentry-source-file="PasswordResetForm.tsx" />
      </div>
      <Button htmlType="submit" type="primary" size="large" className="w-full" loading={isPending} data-sentry-element="Button" data-sentry-source-file="PasswordResetForm.tsx">
        {t('passwordResetForm.button')}
      </Button>
    </form>;
};
export default PasswordResetForm;