'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg width="1em" height="1em" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="ChevronLeft.tsx">
    <path d="M5.47271 1.47108C5.73306 1.21073 5.73306 0.788619 5.47271 0.52827C5.21236 0.26792 4.79025 0.26792 4.5299 0.52827L0.529898 4.52827C0.269548 4.78862 0.269548 5.21073 0.529898 5.47108L4.5299 9.47108C4.79025 9.73143 5.21236 9.73143 5.47271 9.47108C5.73306 9.21073 5.73306 8.78862 5.47271 8.52827L1.94411 4.99967L5.47271 1.47108Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="ChevronLeft.tsx" />
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="ChevronLeft.tsx" />;
};
export default CustomIcon;