import { MessageInstance } from 'antd/es/message/interface';
import { NotificationInstance } from 'antd/es/notification/interface';
import { create } from 'zustand';
interface NotificationState {
  notificationApi: NotificationInstance | undefined;
  setNotificationApi: (api: NotificationInstance) => void;
  messageApi: MessageInstance | undefined;
  setMessageApi: (api: MessageInstance) => void;
}
export const useNotificationStore = create<NotificationState>(set => {
  return {
    notificationApi: undefined,
    setNotificationApi: (api): void => {
      set(() => {
        return {
          notificationApi: api
        };
      });
    },
    messageApi: undefined,
    setMessageApi: (api): void => {
      set(() => {
        return {
          messageApi: api
        };
      });
    }
  };
});