'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="MyAi.tsx">
    <path fillRule="evenodd" clipRule="evenodd" d="M19.0184 3.5318C19.2465 3.2797 19.6417 2.99658 20.244 2.99658C21.2974 2.99658 22.0037 3.92797 22.0037 4.79533C22.0037 6.60909 19.6087 7.99867 19.0184 7.99867C18.4282 7.99867 16.0332 6.60909 16.0332 4.79533C16.0322 3.92797 16.7395 2.99658 17.7929 2.99658C18.3942 2.99658 18.7894 3.2797 19.0184 3.5318Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MyAi.tsx" />
    <path d="M13.0005 3.05661C12.6723 3.01759 12.3392 2.99658 12.0001 2.99658C8.08143 2.99658 4.75705 5.50463 3.51953 8.99908" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MyAi.tsx" />
    <path d="M16.0015 12.0002H14.2678L12.5341 16.0008L9.06462 7.99951L7.19484 12.0002H2.99609" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MyAi.tsx" />
    <path d="M20.9438 11C20.9818 11.3281 21.0038 11.6613 21.0038 12.0004C21.0038 16.9735 16.9731 21.0042 12.0001 21.0042C8.08143 21.0042 4.75705 18.4961 3.51953 15.0017" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MyAi.tsx" />
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="MyAi.tsx" />;
};
export default CustomIcon;