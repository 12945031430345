'use client';

import { Button, Divider, Modal, Segmented } from 'antd';
import Link from 'next/link';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import { useTranslations } from 'next-intl';
import React, { ComponentType, useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'react-use';
import PasswordRecoveryForm from '@/src/shared/components/AuthModal/PasswordRecoveryForm/PasswordRecoveryForm';
import PasswordResetForm from '@/src/shared/components/AuthModal/PasswordResetForm/PasswordResetForm';
import SignInForm from '@/src/shared/components/AuthModal/SignInForm/SignInForm';
import SignUpForm from '@/src/shared/components/AuthModal/SignUpForm/SignUpForm';
import DiscordIcon from '@/src/shared/components/Icons/Discord';
import GoogleIcon from '@/src/shared/components/Icons/Google';
import Image from '@/src/shared/components/Image/Image';
import './AuthModal.scss';
import { isSFW } from '@/src/shared/config/config';
import { RESTORATION_URL } from '@/src/shared/constants/local-storage';
import { NOSFW_SEARCH_PARAMETER_NAME, NOSFW_SEARCH_PARAMETER_VALUE } from '@/src/shared/constants/mixed';
import * as routes from '@/src/shared/constants/routes';
import { AuthModeEnum } from '@/src/shared/types/enums/auth-mode.enum';
import SearchParamManager from '@/src/shared/utilities/helpers/client/url-search-params';
const mapAuthModeToRoute: Record<AuthModeEnum, string> = {
  [AuthModeEnum.SignIn]: routes.SIGN_IN,
  [AuthModeEnum.SignUp]: routes.SIGN_UP,
  [AuthModeEnum.PasswordRecovery]: routes.PASSWORD_RECOVERY,
  [AuthModeEnum.PasswordReset]: routes.PASSWORD_RESET
};
const AuthModal: ComponentType = () => {
  const t = useTranslations();
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();
  const {
    width
  } = useWindowSize();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeMode, setActiveMode] = useState<AuthModeEnum | null>(null);
  const authAction = searchParams.get('authAction');
  const handleClose = useCallback(() => {
    setIsOpen(false);
    setActiveMode(null);
    SearchParamManager.delete(['authAction']);
  }, []);
  const handleOauthRedirect = useCallback((url: string) => {
    // Save the current URL to restore it after the OAuth redirect
    SearchParamManager.delete(['authAction']);
    localStorage.setItem(RESTORATION_URL, pathname);
    router.push(`${url}${!isSFW() ? `?${NOSFW_SEARCH_PARAMETER_NAME}=${NOSFW_SEARCH_PARAMETER_VALUE}` : ''}`, {
      scroll: false
    });
  }, [pathname, router]);
  useEffect(() => {
    const isSignUpQuery: boolean = authAction === AuthModeEnum.SignUp;
    const isSignInQuery: boolean = authAction === AuthModeEnum.SignIn;
    const isPasswordRecoveryQuery: boolean = authAction === AuthModeEnum.PasswordRecovery;
    const isPasswordResetQuery: boolean = authAction === AuthModeEnum.PasswordReset;
    if (isSignUpQuery || isSignInQuery || isPasswordRecoveryQuery || isPasswordResetQuery) {
      setIsOpen(true);
      setActiveMode(() => {
        switch (true) {
          case isSignUpQuery:
            return AuthModeEnum.SignUp;
          case isSignInQuery:
            return AuthModeEnum.SignIn;
          case isPasswordRecoveryQuery:
            return AuthModeEnum.PasswordRecovery;
          case isPasswordResetQuery:
            return AuthModeEnum.PasswordReset;
          default:
            return null;
        }
      });
    }
  }, [authAction, pathname, handleClose]);
  const renderForm = useMemo(() => {
    switch (activeMode) {
      case AuthModeEnum.SignUp:
        return <SignUpForm onModalClose={handleClose} />;
      case AuthModeEnum.SignIn:
        return <SignInForm onModalClose={handleClose} />;
      case AuthModeEnum.PasswordRecovery:
        return <PasswordRecoveryForm onModalClose={handleClose} />;
      case AuthModeEnum.PasswordReset:
        return <PasswordResetForm onModalClose={handleClose} />;
      default:
        return null;
    }
  }, [handleClose, activeMode]);
  const renderSwitchAction = useMemo(() => {
    switch (activeMode) {
      case AuthModeEnum.SignUp:
      case AuthModeEnum.PasswordRecovery:
        return <p className="text-md md:text-center">
            {t('authModal.letsSignIn')}{' '}
            <Link href={routes.SIGN_IN} className="text-primary-500 font-medium">
              {t('common.signIn')}
            </Link>
          </p>;
      case AuthModeEnum.SignIn:
      case AuthModeEnum.PasswordReset:
        return <p className="text-md md:text-center">
            {t('authModal.letsSignIn')}{' '}
            <Link href={routes.SIGN_UP} className="text-primary-500 font-medium">
              {t('common.signUp')}
            </Link>
          </p>;
      default:
        return null;
    }
  }, [activeMode, t]);
  const renderTabs = useMemo(() => {
    switch (activeMode) {
      case AuthModeEnum.SignUp:
      case AuthModeEnum.SignIn:
        return <>
            <Segmented options={[{
            value: AuthModeEnum.SignUp,
            label: t('common.signUp')
          }, {
            value: AuthModeEnum.SignIn,
            label: t('common.signIn')
          }]} value={activeMode} onChange={value => {
            setActiveMode(value);
            router.replace(mapAuthModeToRoute[value], {
              scroll: false
            });
          }} block className="!bg-bgBase-500" />
            <Divider plain className="!my-3" />
          </>;
    }
  }, [activeMode, router, t]);
  const isMaskVisible = useMemo(() => width > 640, [width]);
  return <Modal transitionName="ant-modal-slide-up" wrapClassName="AuthModal" title={false} open={isOpen} footer={null} onCancel={handleClose} mask={isMaskVisible} centered closable={false} maskClosable mousePosition={{
    x: 0,
    y: 0
  }} data-sentry-element="Modal" data-sentry-component="AuthModal" data-sentry-source-file="AuthModal.tsx">
      <div className="grid md:grid-cols-[170px_1fr] items-center gap-6">
        <div className="hidden md:block relative aspect-[82/178] -mb-6 shrink-0 self-end">
          <Image src={t('authModal.backgroundImage')} alt="Sign-in person" fill priority className="object-cover -scale-x-100" sizes="170px" data-sentry-element="Image" data-sentry-source-file="AuthModal.tsx" />
        </div>
        <div className="">
          {renderTabs}
          {renderForm}
          <Divider plain className="!my-3" data-sentry-element="Divider" data-sentry-source-file="AuthModal.tsx">
            {t('authModal.alternative')}
          </Divider>
          <div className="grid grid-cols-2 gap-3">
            <Button type="default" icon={<GoogleIcon className="!text-2xl" />} className="w-full" onClick={() => handleOauthRedirect(process.env.NEXT_PUBLIC_GOOGLE_AUTH_REDIRECT_URL ?? '')} data-sentry-element="Button" data-sentry-source-file="AuthModal.tsx">
              Google
            </Button>
            <Button type="default" icon={<DiscordIcon className="!text-2xl" />} className="w-full" onClick={() => handleOauthRedirect(process.env.NEXT_PUBLIC_DISCORD_AUTH_REDIRECT_URL ?? '')} data-sentry-element="Button" data-sentry-source-file="AuthModal.tsx">
              Discord
            </Button>
          </div>
          <Divider plain className="!mt-4 !mb-3" data-sentry-element="Divider" data-sentry-source-file="AuthModal.tsx" />
          {renderSwitchAction}
        </div>
      </div>
    </Modal>;
};
export default AuthModal;