'use client';

import { usePathname } from 'next/navigation';
import { ComponentType, useMemo, JSX } from 'react';
import { matchRoute } from '@/src/shared/utilities/helpers/universal/helpers';
interface Props {
  children: JSX.Element;
  forbiddenRoutes: string[];
}
const SegmentGuard: ComponentType<Props> = ({
  children,
  forbiddenRoutes = []
}) => {
  const pathname = usePathname();
  const shouldNotRender = useMemo(() => !!forbiddenRoutes.find(route => matchRoute(route, pathname)), [forbiddenRoutes, pathname]);
  return !shouldNotRender ? children : null;
};
export default SegmentGuard;