'use client';

import { useSession } from 'next-auth/react';

const useIsAuthenticated = (): boolean => {
  const { status } = useSession();

  return status === 'authenticated';
};

export default useIsAuthenticated;
