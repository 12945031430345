import { IsEmail, IsNotEmpty, IsString } from 'class-validator';
import { SignInInterface } from '@/src/shared/components/AuthModal/SignInForm/types/sign-in.interface';

export class SignInSchema implements SignInInterface {
  @IsEmail(undefined, { message: 'Please enter valid email' })
  @IsNotEmpty({ message: 'Please enter email' })
  email!: string;

  @IsString()
  @IsNotEmpty({ message: 'Please enter password' })
  password!: string;
}
