'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg width="1em" height="1em" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="Facebook.tsx">
    <path fillRule="evenodd" clipRule="evenodd" d="M32 16.2559C32 7.41999 24.8358 0.255859 16 0.255859C7.16413 0.255859 0 7.41999 0 16.2559C0 24.2412 5.85016 30.8608 13.5002 32.0625V20.882H9.43664V16.2559H13.5002V12.73C13.5002 8.72042 15.8898 6.50415 19.5438 6.50415C21.2943 6.50415 23.1258 6.81688 23.1258 6.81688V10.7545H21.1075C19.1208 10.7545 18.4998 11.9875 18.4998 13.2543V16.2557H22.9367L22.228 20.8818H18.4996V32.0623C26.1498 30.863 31.9998 24.2434 31.9998 16.2557L32 16.2559Z" fill="#1977F3" data-sentry-element="path" data-sentry-source-file="Facebook.tsx" />
    <path fillRule="evenodd" clipRule="evenodd" d="M22.2278 20.8816L22.9367 16.2555H18.4996V13.254C18.4996 11.9895 19.1184 10.7543 21.1074 10.7543H23.1258V6.81666C23.1258 6.81666 21.2943 6.50391 19.5437 6.50391C15.8897 6.50391 13.5001 8.71799 13.5001 12.7298V16.2556H9.43652V20.8817H13.5001V32.0623C14.3146 32.1903 15.1494 32.2556 15.9999 32.2556C16.8504 32.2556 17.6852 32.1881 18.4996 32.0623V20.8817H22.228L22.2278 20.8816Z" fill="#FEFEFE" data-sentry-element="path" data-sentry-source-file="Facebook.tsx" />
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="Facebook.tsx" />;
};
export default CustomIcon;