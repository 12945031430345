'use client';

import { AntdRegistry } from '@ant-design/nextjs-registry';
import { DehydratedState, HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider, message, notification } from 'antd';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { NextIntlClientProvider } from 'next-intl';
import { ComponentType, JSX, useEffect } from 'react';
import { AbstractIntlMessages } from 'use-intl';
import getQueryClient from '@/src/shared/config/queryClient';
import antDesign from '@/src/shared/config/theme/antDesign';
import { useNotificationStore } from '@/src/shared/hooks/stores/useNotificationStore';
import useMixpanel from '@/src/shared/hooks/useMixpanel';
import { LocaleEnum } from '@/src/shared/types/enums/locale.enum';
interface Props {
  children: JSX.Element;
  nextAuthSession?: Session | null;
  locale: LocaleEnum;
  localeMessages?: AbstractIntlMessages;
  dehydratedState: DehydratedState;
}
const ContextProviders: ComponentType<Props> = ({
  children,
  nextAuthSession,
  locale,
  localeMessages,
  dehydratedState
}) => {
  const [notificationApi, notificationContextHolder] = notification.useNotification({
    placement: 'topRight',
    duration: 3,
    showProgress: true,
    pauseOnHover: true
  });
  const [messageApi, messageContextHolder] = message.useMessage({
    duration: 2,
    maxCount: 3
  });
  const {
    setNotificationApi,
    setMessageApi
  } = useNotificationStore();
  const {
    setUser
  } = useMixpanel();
  useEffect(() => {
    setNotificationApi(notificationApi);
    setMessageApi(messageApi);
  }, [notificationApi, setNotificationApi, messageApi, setMessageApi]);
  useEffect(() => {
    setUser();
  }, [setUser]);
  return <NextIntlClientProvider locale={locale} messages={localeMessages} timeZone="Asia/Tbilisi" data-sentry-element="NextIntlClientProvider" data-sentry-component="ContextProviders" data-sentry-source-file="ContextProviders.tsx">
      <SessionProvider session={nextAuthSession} data-sentry-element="SessionProvider" data-sentry-source-file="ContextProviders.tsx">
        <AntdRegistry data-sentry-element="AntdRegistry" data-sentry-source-file="ContextProviders.tsx">
          <ConfigProvider theme={antDesign} data-sentry-element="ConfigProvider" data-sentry-source-file="ContextProviders.tsx">
            {notificationContextHolder}
            {messageContextHolder}
            <QueryClientProvider client={getQueryClient()} data-sentry-element="QueryClientProvider" data-sentry-source-file="ContextProviders.tsx">
              <HydrationBoundary state={dehydratedState} data-sentry-element="HydrationBoundary" data-sentry-source-file="ContextProviders.tsx">
                {children}
              </HydrationBoundary>
            </QueryClientProvider>
          </ConfigProvider>
        </AntdRegistry>
      </SessionProvider>
    </NextIntlClientProvider>;
};
export default ContextProviders;