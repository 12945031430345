import { ComponentType, HTMLAttributes } from 'react';
interface Props extends HTMLAttributes<HTMLSpanElement> {
  svg: ComponentType;
  spin?: boolean;
}
export interface IconProps extends Omit<Props, 'svg'> {}
const Icon: ComponentType<Props> = ({
  svg: Svg,
  className = '',
  spin = false,
  ...rest
}) => {
  return <span className={`anticon ${spin ? 'animate-spin' : ''} ${className}`} {...rest} data-sentry-component="Icon" data-sentry-source-file="Icon.tsx">
      <Svg data-sentry-element="Svg" data-sentry-source-file="Icon.tsx" />
    </span>;
};
export default Icon;