import { IsBoolean } from 'class-validator';
import { PrivacyManagementType } from '@/src/shared/components/PrivacyModals/PrivacyManagementModal/types/privacy-management.type';
import { CookieStoringCategoryEnum } from '@/src/shared/types/enums/cookie-storing-category.enum';

export class PrivacyManagementSchema implements PrivacyManagementType {
  @IsBoolean()
  [CookieStoringCategoryEnum.ESSENTIAL]!: boolean;

  @IsBoolean()
  [CookieStoringCategoryEnum.ANALYTIC]!: boolean;

  @IsBoolean()
  [CookieStoringCategoryEnum.MARKETING]!: boolean;
}
