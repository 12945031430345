'use client';

import { Badge } from 'antd';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { ComponentType, useCallback, useMemo } from 'react';
import { useWindowSize } from 'react-use';
import ChatIcon from '@/src/shared/components/Icons/Chat';
import CompassIcon from '@/src/shared/components/Icons/Compass';
import DiamondIcon from '@/src/shared/components/Icons/Diamond';
import GridIcon from '@/src/shared/components/Icons/Grid';
import MyAiIcon from '@/src/shared/components/Icons/MyAi';
import SprinklesIcon from '@/src/shared/components/Icons/Sprinkles';
import Menu from '@/src/shared/components/Layout/NavigationBar/Menu/Menu';
import { MenuItemProps } from '@/src/shared/components/Layout/NavigationBar/Menu/MenuItem/MenuItem';
import ToggleMenuBarButton from '@/src/shared/components/Layout/NavigationBar/ToggleMenuBarButton/ToggleMenuBarButton';
import { isSFW } from '@/src/shared/config/config';
import colors from '@/src/shared/config/theme/colors';
import * as routes from '@/src/shared/constants/routes';
import './NavigationBar.scss';
import { useUiStore } from '@/src/shared/hooks/stores/useUiStore';
import { useIsPremium } from '@/src/shared/hooks/useIsPremium';
const NavigationBar: ComponentType = () => {
  const t = useTranslations();
  const isPremium = useIsPremium();
  const pathname = usePathname();
  const {
    isMenuBarOpen
  } = useUiStore();
  const {
    width
  } = useWindowSize();
  const filterNoSFWRoutes = useCallback(({
    route
  }: {
    route: string;
  }): boolean => isSFW() ? !routes.NO_SFW_ROUTES.includes(route) : true, []);
  const filterPremiumRoutes = useCallback(({
    route
  }: {
    route: string;
  }): boolean => !(isPremium && routes.PREMIUM_RESTRICTED_ROUTES.includes(route)), [isPremium]);
  const items: MenuItemProps[] = useMemo(() => [{
    key: routes.HOME,
    icon: <CompassIcon />,
    text: t('menu.home'),
    route: routes.HOME
  }, {
    key: routes.CHAT,
    icon: <span className="flex items-start relative">
              <ChatIcon className="!mb-0" />
              {!pathname.includes(routes.CHAT) && <Badge dot className="!flex !absolute !top-0 !right-0 !m-0" color={colors.primary['500']} status="processing" />}
            </span>,
    text: t('menu.chat'),
    route: routes.CHAT,
    className: '!overflow-visible'
  }, {
    key: routes.GALLERY,
    icon: <GridIcon />,
    text: t('menu.gallery'),
    route: routes.GALLERY
  }, {
    key: routes.MY_CHARACTERS,
    icon: <MyAiIcon />,
    text: t('menu.myCharacters'),
    route: routes.MY_CHARACTERS
  }, {
    key: routes.CREATE_CHARACTER,
    icon: <SprinklesIcon />,
    text: <>
              <span className="sm:hidden">
                {t('menu.createCharacter.short')}
              </span>
              <span className="max-sm:hidden">
                {t('menu.createCharacter.long')}
              </span>
            </>,
    route: routes.CREATE_CHARACTER
  }, {
    key: 'UPGRADE_TO_PREMIUM',
    icon: <DiamondIcon />,
    text: t('menu.upgradeToPremium'),
    route: routes.PRICING,
    className: 'MenuItem--premium'
  }].filter(filterNoSFWRoutes).filter(filterPremiumRoutes), [filterNoSFWRoutes, filterPremiumRoutes, pathname, t]);
  const activeKey = useMemo(() => {
    switch (true) {
      case pathname.includes(routes.CHAT):
        return routes.CHAT;
      case pathname.includes(routes.GALLERY):
        return routes.GALLERY;
      default:
        return pathname;
    }
  }, [pathname]);
  const isTooltipVisible = useMemo(() => width > 1280 && !isMenuBarOpen, [width, isMenuBarOpen]);
  return <div className={`
        NavigationBar
        ${!isMenuBarOpen ? 'NavigationBar--minimized' : ''}
      `} data-sentry-component="NavigationBar" data-sentry-source-file="NavigationBar.tsx">
      <ToggleMenuBarButton data-sentry-element="ToggleMenuBarButton" data-sentry-source-file="NavigationBar.tsx" />
      <Menu items={items} activeKey={activeKey} showTooltip={isTooltipVisible} data-sentry-element="Menu" data-sentry-source-file="NavigationBar.tsx" />
    </div>;
};
export default NavigationBar;