import { IsNotEmpty, IsString, IsStrongPassword } from 'class-validator';
import { PasswordResetInterface } from '@/src/shared/components/AuthModal/PasswordResetForm/types/password-reset.interface';
import { Match } from '@/src/shared/utilities/helpers/client/validators';

export class PasswordResetSchema implements PasswordResetInterface {
  @IsString()
  @IsNotEmpty()
  @IsStrongPassword(
    {
      minLength: 8,
      minUppercase: 1,
      minLowercase: 0,
      minNumbers: 0,
      minSymbols: 0,
    },
    {
      message: 'Min 8 characters long, at least one uppercase letter',
    },
  )
  password!: string;

  @IsString()
  @IsNotEmpty({ message: 'Please confirm your password' })
  @Match('password', { message: 'Passwords do not match' })
  confirmPassword!: string;

  token!: string;

  email!: string;
}
