'use client';

import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { signIn } from 'next-auth/react';
import { AuthResponseInterface } from '@/src/shared/api/types/interfaces/auth-response.interface';

export const useSignInWithTokenMutation = (): UseMutationResult<
  void,
  Error,
  AuthResponseInterface
> => {
  return useMutation({
    mutationFn: async (values) => {
      const result = await signIn('OAUTH', {
        accessToken: values.accessToken,
        refreshToken: values.refreshToken,
        redirect: false,
      });

      if (result?.error) {
        throw new Error(result.error);
      }
    },
  });
};
