import { ComponentType } from 'react';
import MenuItem, { MenuItemProps } from '@/src/shared/components/Layout/NavigationBar/Menu/MenuItem/MenuItem';
import './Menu.scss';
interface Props {
  items: MenuItemProps[];
  activeKey: string;
  className?: string;
  showTooltip?: boolean;
}
const Menu: ComponentType<Props> = ({
  items,
  activeKey,
  className = '',
  showTooltip
}) => {
  return <div className={`Menu ${className}`} data-sentry-component="Menu" data-sentry-source-file="Menu.tsx">
      {items.map(({
      key,
      ...rest
    }) => {
      return <MenuItem key={key} isActive={activeKey === key} showTooltip={showTooltip} {...rest} />;
    })}
    </div>;
};
export default Menu;