import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Button, Form, Input } from 'antd';
import { useTranslations } from 'next-intl';
import { ComponentType, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import HttpError from '@/src/shared/api/http-error';
import { SignUpSchema } from '@/src/shared/components/AuthModal/SignUpForm/schemas/sign-up.schema';
import EmailIcon from '@/src/shared/components/Icons/Email';
import LockIcon from '@/src/shared/components/Icons/Lock';
import { useSignInWithTokenMutation } from '@/src/shared/hooks/queries/useSignInWithTokenMutation';
import { useSignUpMutation } from '@/src/shared/hooks/queries/useSignUpMutation';
import { useNotificationStore } from '@/src/shared/hooks/stores/useNotificationStore';
import useMixpanel, { MixpanelEventEnum } from '@/src/shared/hooks/useMixpanel';
interface Props {
  onModalClose: () => void;
}
const SignUpForm: ComponentType<Props> = ({
  onModalClose
}: Props) => {
  const t = useTranslations();
  const {
    notificationApi,
    messageApi
  } = useNotificationStore();
  const {
    setUser,
    trackEvent
  } = useMixpanel();
  const {
    mutateAsync: mutateRegistrationAsync,
    isPending: isRegistrationPending
  } = useSignUpMutation();
  const {
    mutateAsync: mutateLoginAsync,
    isPending: isLoginPending
  } = useSignInWithTokenMutation();
  const isLoading = isRegistrationPending || isLoginPending;
  const {
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<SignUpSchema>({
    resolver: classValidatorResolver(SignUpSchema),
    mode: 'onChange'
  });
  const onFinish = useCallback(async (values: SignUpSchema) => {
    try {
      const {
        accessToken,
        refreshToken
      } = await mutateRegistrationAsync(values);
      await mutateLoginAsync({
        accessToken,
        refreshToken
      });
      messageApi?.success({
        content: "You've successfully registered and signed in"
      });
      trackEvent(MixpanelEventEnum.SIGN_UP);
      setUser();
      onModalClose();
    } catch (e) {
      const error = e as HttpError;
      notificationApi?.error({
        message: error.name,
        description: error.message
      });
    }
  }, [mutateRegistrationAsync, mutateLoginAsync, messageApi, trackEvent, setUser, onModalClose, notificationApi]);
  return <form onSubmit={handleSubmit(onFinish)} data-sentry-component="SignUpForm" data-sentry-source-file="SignUpForm.tsx">
      <div className="flex flex-col gap-2 mb-5">
        <Controller render={({
        field
      }) => {
        return <Form.Item validateStatus={errors.email ? 'error' : undefined} help={errors.email?.message}>
                <Input size="large" placeholder={t('common.email')} prefix={<EmailIcon className="text-xl" />} disabled={isLoading} {...field} />
              </Form.Item>;
      }} name="email" control={control} data-sentry-element="Controller" data-sentry-source-file="SignUpForm.tsx" />

        <Controller render={({
        field
      }) => {
        return <Form.Item validateStatus={errors.password ? 'error' : undefined} help={errors.password?.message}>
                <Input.Password size="large" placeholder={t('common.password')} visibilityToggle prefix={<LockIcon className="text-xl" />} disabled={isLoading} {...field} />
              </Form.Item>;
      }} name="password" control={control} data-sentry-element="Controller" data-sentry-source-file="SignUpForm.tsx" />

        <Controller render={({
        field
      }) => {
        return <Form.Item validateStatus={errors.confirmPassword ? 'error' : undefined} help={errors.confirmPassword?.message}>
                <Input.Password size="large" placeholder={t('common.confirmPassword')} visibilityToggle prefix={<LockIcon className="text-xl" />} disabled={isLoading} {...field} />
              </Form.Item>;
      }} name="confirmPassword" control={control} data-sentry-element="Controller" data-sentry-source-file="SignUpForm.tsx" />
      </div>
      <Button htmlType="submit" type="primary" size="large" className="w-full" loading={isLoading} data-sentry-element="Button" data-sentry-source-file="SignUpForm.tsx">
        {t('signUpForm.button')}
      </Button>
    </form>;
};
export default SignUpForm;