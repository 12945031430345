'use client';

import { Button, Modal } from 'antd';
import { useSearchParams } from 'next/navigation';
import { useTranslations } from 'next-intl';
import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import SubscriptionCrown from '../Icons/SubscriptionCrown';
import SearchParamManager from '@/src/shared/utilities/helpers/client/url-search-params';
export const PREMIUM_PURCHASED_MODAL_SEARCH_PARAM = 'premiumPurchased';
const PremiumPurchasedModal: ComponentType = () => {
  const t = useTranslations();
  const searchParams = useSearchParams();
  const isOpenSearchParam = searchParams.get(PREMIUM_PURCHASED_MODAL_SEARCH_PARAM);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    setIsModalOpen(!!isOpenSearchParam);
  }, [isOpenSearchParam]);
  const handleClose = useCallback((): void => {
    SearchParamManager.delete([PREMIUM_PURCHASED_MODAL_SEARCH_PARAM]);
  }, []);
  return <Modal closable centered onCancel={handleClose} open={isModalOpen} className="!p-4 !max-w-[400px] !w-full" classNames={{
    content: '!p-4 sm:!p-6'
  }} footer={null} data-sentry-element="Modal" data-sentry-component="PremiumPurchasedModal" data-sentry-source-file="PremiumPurchasedModal.tsx">
      <div className="flex flex-col items-center">
        <SubscriptionCrown className="!text-6xl !text-primary-500 mb-2 mt-4" data-sentry-element="SubscriptionCrown" data-sentry-source-file="PremiumPurchasedModal.tsx" />

        <h1 className="text-xl font-semibold text-center mb-2">
          {t('premiumPurchasedModal.title')}
        </h1>

        <p className="text-center mb-6">
          {t('premiumPurchasedModal.description')}
        </p>

        <Button type="primary" className="w-full" onClick={handleClose} data-sentry-element="Button" data-sentry-source-file="PremiumPurchasedModal.tsx">
          {t('premiumPurchasedModal.button')}
        </Button>
      </div>
    </Modal>;
};
export default PremiumPurchasedModal;