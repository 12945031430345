const colors = {
  primary: {
    50: '#fff2ff',
    100: '#fee3ff',
    200: '#fdc6ff',
    300: '#ff99fe',
    400: '#ff5dfd',
    500: '#fa21ff',
    600: '#ea00ff',
    700: '#c300cf',
    800: '#a100a9',
    900: '#860689',
    950: '#5b005e',
  },
  link: {
    50: '#fef3ff',
    100: '#fbe6ff',
    200: '#f9ccff',
    300: '#f6a3ff',
    400: '#f36dff',
    500: '#e419ff',
    600: '#d016e3',
    700: '#af0ebd',
    800: '#910e9a',
    900: '#79117e',
    950: '#510055',
  },
  success: {
    50: '#ebfce6',
    100: '#d6f9ce',
    200: '#aaf299',
    300: '#7eeb66',
    400: '#52e433',
    500: '#52c41a', // Base color
    600: '#4ab017',
    700: '#3d9314',
    800: '#317510',
    900: '#25570d',
  },
  warning: {
    50: '#fff7e6',
    100: '#ffefcc',
    200: '#ffdf99',
    300: '#ffcf66',
    400: '#ffbf33',
    500: '#faad14', // Base color
    600: '#e39c12',
    700: '#b37f0f',
    800: '#80620b',
    900: '#4d3b07',
  },
  error: {
    50: '#ffe6e6',
    100: '#ffcccc',
    200: '#ff9999',
    300: '#ff6666',
    400: '#ff3333',
    500: '#f5222d', // Base color
    600: '#de1f29',
    700: '#b31923',
    800: '#88141b',
    900: '#5c0e12',
  },
  textBase: {
    50: '#ffffff',
    100: '#ffffff',
    200: '#ffffff',
    300: '#ffffff',
    400: '#ffffff',
    500: '#ffffff', // Base color
    600: '#C2CAD1',
    700: '#c0c0c0',
    800: '#a0a0a0',
    900: '#808080',
  },
  bgBase: {
    50: '#d1d1d1',
    100: '#a3a3a3',
    200: '#767676',
    300: '#29252d',
    350: '#19161b',
    400: '#0e0c0e',
    500: '#080708', // Base color
    600: '#070607',
    700: '#050605',
    800: '#040404',
    900: '#030303',
  },
  divider: '#201a26' + '',
};

export default colors;
