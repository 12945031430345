import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { UI_STORE } from '@/src/shared/constants/local-storage';
interface UiState {
  isMenuBarOpen: boolean;
  setIsMenuBarOpen: (isMenuBarOpen: boolean) => void;
  saleExpirationDate: string | null;
  setSaleExpirationDate: (saleExpirationDate: string) => void;
}
export const useUiStore = create<UiState, [['zustand/persist', UiState]]>(persist(set => {
  return {
    isMenuBarOpen: true,
    setIsMenuBarOpen: (isMenuBarOpen): void => {
      set(() => {
        return {
          isMenuBarOpen
        };
      });
    },
    saleExpirationDate: null,
    setSaleExpirationDate: (saleExpirationDate): void => {
      set(() => {
        return {
          saleExpirationDate
        };
      });
    }
  };
}, {
  name: UI_STORE,
  partialize: state => {
    return {
      isMenuBarOpen: state.isMenuBarOpen,
      saleExpirationDate: state.saleExpirationDate
    } as UiState;
  }
}));