export const avatarImageSrcs = [
  { key: 1, src: '/assets/images/avatars/avatar-1.svg' },
  { key: 2, src: '/assets/images/avatars/avatar-2.svg' },
  { key: 3, src: '/assets/images/avatars/avatar-3.svg' },
  { key: 4, src: '/assets/images/avatars/avatar-4.svg' },
  { key: 5, src: '/assets/images/avatars/avatar-5.svg' },
  { key: 6, src: '/assets/images/avatars/avatar-6.svg' },
  { key: 7, src: '/assets/images/avatars/avatar-7.svg' },
  { key: 8, src: '/assets/images/avatars/avatar-8.svg' },
  { key: 9, src: '/assets/images/avatars/avatar-9.svg' },
  { key: 10, src: '/assets/images/avatars/avatar-10.svg' },
  { key: 11, src: '/assets/images/avatars/avatar-11.svg' },
  { key: 12, src: '/assets/images/avatars/avatar-12.svg' },
];
