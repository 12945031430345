'use client';

import { Button, Dropdown, MenuProps } from 'antd';
import Link from 'next/link';
import { signOut } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { ComponentType } from 'react';
import DiamondIcon from '@/src/shared/components/Icons/Diamond';
import SettingsIcon from '@/src/shared/components/Icons/Settings';
import SignOutIcon from '@/src/shared/components/Icons/SignOut';
import UserIcon from '@/src/shared/components/Icons/User';
import * as routes from '@/src/shared/constants/routes';
import { useMyUserInfo } from '@/src/shared/hooks/queries/useMyUserInfo';
import { useIsPremium } from '@/src/shared/hooks/useIsPremium';
const UserMenu: ComponentType = () => {
  const t = useTranslations();
  const isPremium = useIsPremium();
  const {
    data: user
  } = useMyUserInfo();
  const items: MenuProps['items'] = [{
    key: 'GROUP',
    label: user?.email,
    type: 'group'
  }, ...(!isPremium ? [{
    key: 'UPGRADE',
    label: <Link href={routes.PRICING}>{t('userMenu.upgrade')}</Link>,
    icon: <DiamondIcon className="!text-2xl" />
  }] : []), {
    key: 'SETTINGS',
    label: <Link href={routes.SETTINGS}>{t('userMenu.settings')}</Link>,
    icon: <SettingsIcon className="!text-2xl" />
  }, {
    key: 'LOG_OUT',
    label: t('userMenu.logout'),
    icon: <SignOutIcon className="!text-2xl" />,
    onClick: () => signOut({
      callbackUrl: routes.HOME
    })
  }];
  return <Dropdown menu={{
    items
  }} placement="bottomLeft" data-sentry-element="Dropdown" data-sentry-component="UserMenu" data-sentry-source-file="UserMenu.tsx">
      <Button type="text" shape="circle" icon={<UserIcon className="!text-3xl" />} size="large" data-sentry-element="Button" data-sentry-source-file="UserMenu.tsx" />
    </Dropdown>;
};
export default UserMenu;