'use client';

import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google';
import Cookies from 'js-cookie';
import Script from 'next/script';
import React, { ComponentType, useEffect } from 'react';
import { usePrivacyStore } from '@/src/shared/hooks/stores/usePrivacyStore';
import { CookieStoringCategoryEnum } from '@/src/shared/types/enums/cookie-storing-category.enum';
const ThirdPartyScripts: ComponentType = () => {
  const {
    categories
  } = usePrivacyStore();
  const isAnalyticEnabled = categories?.[CookieStoringCategoryEnum.ANALYTIC] ?? false;
  const isMarketingEnabled = categories?.[CookieStoringCategoryEnum.MARKETING] ?? false;
  useEffect(() => {
    if (!isAnalyticEnabled) {
      clearCookies([
      // GoogleAnalytics
      '_ga', '_gid', '_gat',
      // Microsoft Clarity
      '_clck', '_clsk']);
    }
    if (!isMarketingEnabled) {
      clearCookies([
      // GoogleTagManager cookies
      '_gcl_au', '_ga_QSWQRZXYBS']);
    }
  }, [isAnalyticEnabled, isMarketingEnabled]);
  const clearCookies = (cookieNames: string[]): void => {
    cookieNames.forEach(cookieName => {
      Cookies.remove(cookieName, {
        path: '/'
      });
    });
  };
  return <>
      {isAnalyticEnabled && <>
          <GoogleAnalytics gaId="G-QSWQRZXYBS" />
          <Script id="microsoft-clarity-script">
            {`
              (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "ofxic1rgp1");
            `}
          </Script>
        </>}

      {isMarketingEnabled && <GoogleTagManager gtmId="GTM-WS9GQMWF" />}
    </>;
};
export default ThirdPartyScripts;