import {
  IsEmail,
  IsNotEmpty,
  IsString,
  IsStrongPassword,
} from 'class-validator';
import { SignUpInterface } from '@/src/shared/components/AuthModal/SignUpForm/types/sign-up.interface';
import { Match } from '@/src/shared/utilities/helpers/client/validators';

export class SignUpSchema implements SignUpInterface {
  @IsEmail(undefined, { message: 'Please enter valid email' })
  @IsNotEmpty({ message: 'Please enter email' })
  email!: string;

  @IsString()
  @IsNotEmpty()
  @IsStrongPassword(
    {
      minLength: 8,
      minUppercase: 1,
      minLowercase: 0,
      minNumbers: 0,
      minSymbols: 0,
    },
    {
      message: 'Min 8 characters long, at least one uppercase letter',
    },
  )
  password!: string;

  @Match('password', { message: 'Passwords do not match' })
  confirmPassword!: string;
}
