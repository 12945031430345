'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg width="1em" height="1em" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="Grid.tsx">
    <rect x="3.49634" y="3.49625" width="7.00292" height="7.00292" rx="1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="rect" data-sentry-source-file="Grid.tsx" />
    <rect x="14.501" y="3.49625" width="7.00292" height="7.00292" rx="1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="rect" data-sentry-source-file="Grid.tsx" />
    <rect x="3.49634" y="14.5008" width="7.00292" height="7.00292" rx="1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="rect" data-sentry-source-file="Grid.tsx" />
    <rect x="14.501" y="14.5008" width="7.00292" height="7.00292" rx="1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="rect" data-sentry-source-file="Grid.tsx" />
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="Grid.tsx" />;
};
export default CustomIcon;