import { Tooltip } from 'antd';
import Link from 'next/link';
import React, { ComponentType, MouseEventHandler, ReactNode } from 'react';
import './MenuItem.scss';
export interface MenuItemProps {
  key: string;
  icon: ReactNode;
  text: string | ReactNode;
  route?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  isActive?: boolean;
  className?: string;
  showTooltip?: boolean;
}
const MenuItem: ComponentType<MenuItemProps> = ({
  icon,
  text,
  route,
  onClick,
  isActive = false,
  className = '',
  showTooltip = false
}) => {
  return <Tooltip title={showTooltip ? text : undefined} placement="right" mouseEnterDelay={0} arrow={false} data-sentry-element="Tooltip" data-sentry-component="MenuItem" data-sentry-source-file="MenuItem.tsx">
      <Link href={route ?? ''} onClick={onClick} className={`MenuItem ${isActive ? 'MenuItem--active' : ''} ${className}`} data-sentry-element="Link" data-sentry-source-file="MenuItem.tsx">
        <div className="MenuItem__icon">{icon}</div>
        <p className="MenuItem__text">{text}</p>
      </Link>
    </Tooltip>;
};
export default MenuItem;