'use client';

import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AuthResponseInterface } from '@/src/shared/api/types/interfaces/auth-response.interface';
import { SignUpInterface } from '@/src/shared/components/AuthModal/SignUpForm/types/sign-up.interface';
import { mutationFetcherCreator } from '@/src/shared/config/queryClient';

export const useSignUpMutation = (): UseMutationResult<
  AuthResponseInterface,
  Error,
  SignUpInterface
> => {
  return useMutation({
    mutationFn: mutationFetcherCreator('/api/auth/register', 'POST'),
  });
};
