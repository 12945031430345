import { getLCM } from '@/src/shared/utilities/helpers/universal/helpers';

export const GALLERY_PAGE_DATA_PAGINATION_SIZE = getLCM({
  multipliers: [2, 4, 5],
});
export const CHAT_PAGE_CONVERSATIONS_PAGINATION_SIZE = 10;
export const PROMOTED_CHARACTERS_PAGE_PAGINATION_SIZE = getLCM({
  multipliers: [2, 3, 4],
  min: 50,
  max: 100,
});

export const NOSFW_SEARCH_PARAMETER_NAME = 'jsakfhdvsywueifc';
export const NOSFW_SEARCH_PARAMETER_VALUE = 'ksjbfkbsdcjb';
