'use client';

import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AuthResponseInterface } from '@/src/shared/api/types/interfaces/auth-response.interface';
import { PasswordResetInterface } from '@/src/shared/components/AuthModal/PasswordResetForm/types/password-reset.interface';
import { mutationFetcherCreator } from '@/src/shared/config/queryClient';

export const usePasswordResetMutation = (): UseMutationResult<
  AuthResponseInterface,
  Error,
  PasswordResetInterface
> => {
  return useMutation({
    mutationFn: mutationFetcherCreator('/api/auth/password-reset', 'POST'),
  });
};
