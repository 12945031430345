import {
  defaultShouldDehydrateQuery,
  isServer,
  QueryClient,
  QueryClientConfig,
  QueryFunctionContext,
} from '@tanstack/react-query';
import HttpError from '@/src/shared/api/http-error';

export const fetcher = async <TResponse>(url: string): Promise<TResponse> => {
  const response = await fetch(url);

  if (!response.ok) {
    const errorMessage = `HTTP error! status: ${response.status}`;
    const errorData = await response.json().catch(() => ({})); // Try to parse error response body
    throw new HttpError({
      status: response.status,
      message: errorMessage,
      data: errorData,
    });
  }

  return response.json();
};

const queryFunction = async ({
  queryKey,
}: QueryFunctionContext): Promise<unknown> => {
  const url = queryKey[0] as string; // queryKey is an array, and we extract the URL from it
  return fetcher(url);
};

export const mutationFetcherCreator = <TResponse, TBody>(
  url: string,
  method: 'POST' | 'GET' | 'PUT' | 'DELETE',
) => {
  return async (body: TBody): Promise<TResponse> => {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new HttpError({
        status: response.status,
        message: data.message,
        data: data,
      });
    }

    return data;
  };
};

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      queryFn: queryFunction,
      staleTime: 1000 * 60 * 3,
    },
    dehydrate: {
      // include pending queries in dehydration
      shouldDehydrateQuery: (query): boolean =>
        defaultShouldDehydrateQuery(query) || query.state.status === 'pending',
    },
  },
};

const makeQueryClient = (): QueryClient => {
  return new QueryClient(queryClientConfig);
};

let browserQueryClient: QueryClient | undefined = undefined;

const getQueryClient = (): QueryClient => {
  if (isServer) {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
};

export default getQueryClient;
