'use client';

import { Button } from 'antd';
import React, { ComponentType } from 'react';
import ChevronLeft from '@/src/shared/components/Icons/ChevronLeft';
import { useUiStore } from '@/src/shared/hooks/stores/useUiStore';
const ToggleMenuBarButton: ComponentType = () => {
  const {
    isMenuBarOpen,
    setIsMenuBarOpen
  } = useUiStore();
  return <Button type="text" icon={<ChevronLeft className={`!text-xs ${!isMenuBarOpen ? '-scale-x-100 ml-0.5' : 'mr-0.5'}`} />} size="small" className="!hidden xl:!flex !border-divider !bg-bgBase-400 !rounded-lg !absolute !top-[14px] !-right-[14px] z-40 !h-[28px] !w-[28px]" onClick={() => setIsMenuBarOpen(!isMenuBarOpen)} data-sentry-element="Button" data-sentry-component="ToggleMenuBarButton" data-sentry-source-file="ToggleMenuBarButton.tsx" />;
};
export default ToggleMenuBarButton;