'use client';

import { Button, Modal } from 'antd';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import { ComponentType, useEffect } from 'react';
import './PrivacyAlertModal.scss';
import SettingsIcon from '@/src/shared/components/Icons/Settings';
import * as routes from '@/src/shared/constants/routes';
import { usePrivacyStore } from '@/src/shared/hooks/stores/usePrivacyStore';
import { CookieStoringCategoryEnum } from '@/src/shared/types/enums/cookie-storing-category.enum';
const PrivacyAlertModal: ComponentType = () => {
  const t = useTranslations();
  const {
    _isHydrated,
    isPrivacyAlertModalOpen,
    setIsPrivacyAlertModalOpen,
    setIsPrivacyManagementModalOpen,
    categories,
    setCategories
  } = usePrivacyStore();
  useEffect(() => {
    if (_isHydrated && !categories) {
      setIsPrivacyAlertModalOpen(true);
    }
  }, [_isHydrated, categories, setIsPrivacyAlertModalOpen]);
  const handleClose = (): void => {
    setIsPrivacyAlertModalOpen(false);
  };
  const handleReject = (): void => {
    const allCategoriesFalseExceptEssential = Object.values(CookieStoringCategoryEnum).reduce((categories, category) => {
      categories[category] = category === CookieStoringCategoryEnum.ESSENTIAL;
      return categories;
    }, {} as Record<CookieStoringCategoryEnum, boolean>);
    setCategories(allCategoriesFalseExceptEssential);
    handleClose();
  };
  const handleAccept = (): void => {
    const allCategoriesTrue = Object.values(CookieStoringCategoryEnum).reduce((categories, category) => {
      categories[category] = true;
      return categories;
    }, {} as Record<CookieStoringCategoryEnum, boolean>);
    setCategories(allCategoriesTrue);
    handleClose();
  };
  const handleManage = (): void => {
    handleClose();
    setIsPrivacyManagementModalOpen(true);
  };
  return <Modal transitionName="ant-modal-slide-up" wrapClassName="PrivacyAlertModal" title={false} open={isPrivacyAlertModalOpen} footer={null} mask centered closable={false} maskClosable mousePosition={{
    x: 0,
    y: 0
  }} data-sentry-element="Modal" data-sentry-component="PrivacyAlertModal" data-sentry-source-file="PrivacyAlertModal.tsx">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 sm:gap-6 md:items-center w-full max-w-screen-lg mx-auto">
        <div className="col-span-full md:col-span-8 flex flex-col gap-1 text-center md:text-left">
          <h2 className="text-lg md:text-lg font-medium -mt-1">
            {t('privacyAlertModal.title')}
          </h2>
          <p className="leading-snug opacity-75">
            {t.rich('privacyAlertModal.description', {
            a: children => <Link href={routes.PRIVACY_POLICY}>{children}</Link>
          })}
          </p>
        </div>
        <div className="col-span-full md:col-span-4 grid grid-cols-2 gap-2">
          <Button type="text" className="!border !border-divider uppercase" onClick={handleReject} data-sentry-element="Button" data-sentry-source-file="PrivacyAlertModal.tsx">
            {t('privacyAlertModal.buttons.reject')}
          </Button>
          <Button type="primary" className="uppercase" onClick={handleAccept} data-sentry-element="Button" data-sentry-source-file="PrivacyAlertModal.tsx">
            {t('privacyAlertModal.buttons.accept')}
          </Button>
          <Button type="default" className="col-span-full uppercase !border !border-divider" icon={<SettingsIcon />} onClick={handleManage} data-sentry-element="Button" data-sentry-source-file="PrivacyAlertModal.tsx">
            {t('privacyAlertModal.buttons.manage')}
          </Button>
        </div>
      </div>
    </Modal>;
};
export default PrivacyAlertModal;