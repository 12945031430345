import { UserModel } from '@project-adonis/validators';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import useIsAuthenticated from '@/src/shared/hooks/useIsAuthenticated';

export const useMyUserInfo = (): UseQueryResult<UserModel> => {
  const isAuthenticated = useIsAuthenticated();
  return useQuery({
    queryKey: ['/api/users/me'],
    enabled: isAuthenticated,
  });
};
