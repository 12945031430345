'use client';

import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Button, Checkbox, List, Modal } from 'antd';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import { ComponentType, ReactNode } from 'react';
import { Controller, useForm } from 'react-hook-form';
import './PrivacyManagementModal.scss';
import { PrivacyManagementSchema } from '@/src/shared/components/PrivacyModals/PrivacyManagementModal/schemas/privacy-management.schema';
import { usePrivacyStore } from '@/src/shared/hooks/stores/usePrivacyStore';
import { CookieStoringCategoryEnum } from '@/src/shared/types/enums/cookie-storing-category.enum';
interface FormListItemInterface {
  key: CookieStoringCategoryEnum;
  title: string;
  description: string | ReactNode;
  disabled?: boolean;
}
const defaultValues = Object.values(CookieStoringCategoryEnum).reduce((categories, category) => {
  categories[category] = category === CookieStoringCategoryEnum.ESSENTIAL;
  return categories;
}, {} as Record<CookieStoringCategoryEnum, boolean>);
const PrivacyManagementModal: ComponentType = () => {
  const t = useTranslations();
  const {
    isPrivacyManagementModalOpen,
    setIsPrivacyManagementModalOpen,
    categories,
    setCategories
  } = usePrivacyStore();
  const {
    control,
    handleSubmit,
    trigger,
    setValue
  } = useForm<PrivacyManagementSchema>({
    resolver: classValidatorResolver(PrivacyManagementSchema),
    mode: 'onBlur',
    defaultValues: {
      ...(categories ? categories : defaultValues)
    }
  });
  const onFinish = (values: PrivacyManagementSchema): void => {
    setCategories(values);
    handleClose();
  };
  const handleClose = (): void => {
    setIsPrivacyManagementModalOpen(false);
  };
  const handleAccept = async (): Promise<void> => {
    Object.values(CookieStoringCategoryEnum).forEach(key => {
      setValue(key, true);
    });
    const isValid = await trigger();
    if (isValid) {
      await handleSubmit(onFinish)();
    }
    handleClose();
  };
  const categoryList: FormListItemInterface[] = [{
    key: CookieStoringCategoryEnum.ESSENTIAL,
    title: t('privacyManagementModal.categories.essential.title'),
    description: t('privacyManagementModal.categories.essential.description'),
    disabled: true
  }, {
    key: CookieStoringCategoryEnum.ANALYTIC,
    title: t('privacyManagementModal.categories.analytic.title'),
    description: t.rich('privacyManagementModal.categories.analytic.description', {
      googleAnalytics: children => <Link href="https://developers.google.com/analytics" className="text-primary-500" target="_blank">
              {children}
            </Link>,
      microsoftClarity: children => <Link href="https://clarity.microsoft.com" className="text-primary-500" target="_blank">
              {children}
            </Link>,
      mixpanel: children => <Link href="https://mixpanel.com" className="text-primary-500" target="_blank">
              {children}
            </Link>
    })
  }, {
    key: CookieStoringCategoryEnum.MARKETING,
    title: t('privacyManagementModal.categories.marketing.title'),
    description: t.rich('privacyManagementModal.categories.marketing.description', {
      googleTagManager: children => <Link href="https://tagmanager.google.com" className="text-primary-500" target="_blank">
              {children}
            </Link>
    })
  }];
  return <Modal transitionName="ant-modal-slide-up" wrapClassName="PrivacyManagementModal" title={false} open={isPrivacyManagementModalOpen} footer={null} mask centered closable={false} maskClosable mousePosition={{
    x: 0,
    y: 0
  }} data-sentry-element="Modal" data-sentry-component="PrivacyManagementModal" data-sentry-source-file="PrivacyManagementModal.tsx">
      <form onSubmit={handleSubmit(onFinish)} className="flex flex-col gap-2 md:gap-3">
        <h2 className="text-lg md:text-lg font-medium uppercase">
          {t('privacyManagementModal.title')}
        </h2>
        <div className="">
          <List itemLayout="horizontal" dataSource={categoryList} rowKey="key" renderItem={({
          key,
          title,
          description,
          disabled = false
        }) => {
          return <List.Item actions={[<Controller key={key} name={key} control={control} render={({
            field
          }) => <Checkbox {...disabled ? {} : field} checked={field.value} className={disabled ? 'opacity-50' : ''} />} />]}>
                  <List.Item.Meta title={title} description={description} />
                </List.Item>;
        }} data-sentry-element="List" data-sentry-source-file="PrivacyManagementModal.tsx" />
        </div>
        <div className="grid grid-cols-2 gap-2 md:gap-4">
          <Button htmlType="submit" type="text" className="!border !border-divider uppercase" data-sentry-element="Button" data-sentry-source-file="PrivacyManagementModal.tsx">
            {t('privacyManagementModal.buttons.save')}
          </Button>
          <Button htmlType="button" type="primary" className="uppercase" onClick={handleAccept} data-sentry-element="Button" data-sentry-source-file="PrivacyManagementModal.tsx">
            {t('privacyManagementModal.buttons.accept')}
          </Button>
        </div>
      </form>
    </Modal>;
};
export default PrivacyManagementModal;