'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="SubscriptionCrown.tsx">
    <circle cx="15.9997" cy="16.0002" r="12.6667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="circle" data-sentry-source-file="SubscriptionCrown.tsx" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.737 12.4145L19.4806 12.8667L16.9439 10.325C16.6937 10.0744 16.3542 9.93359 16.0001 9.93359C15.6461 9.93359 15.3065 10.0744 15.0564 10.325L12.5197 12.8667L10.2633 12.4145C9.8123 12.3242 9.34657 12.4726 9.03108 12.8073C8.71559 13.142 8.59489 13.6157 8.71173 14.0606L10.5183 20.9389C10.6722 21.5249 11.2019 21.9335 11.8079 21.9335H20.1924C20.7984 21.9335 21.3281 21.5249 21.482 20.9389L23.2886 14.0605C23.4054 13.6157 23.2847 13.142 22.9692 12.8073C22.6537 12.4726 22.188 12.3242 21.737 12.4145Z" fill="currentColor" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="SubscriptionCrown.tsx" />
    <path d="M15.6671 17.3346V17.3337C15.6673 17.1501 15.8163 17.0014 15.9999 17.0015C16.1835 17.0016 16.3323 17.1505 16.3323 17.3341C16.3323 17.5178 16.1835 17.6666 15.9999 17.6668C15.8163 17.6669 15.6673 17.5182 15.6671 17.3346" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="SubscriptionCrown.tsx" />
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="SubscriptionCrown.tsx" />;
};
export default CustomIcon;