import { ThemeConfig } from 'antd';
import { NextFont } from 'next/dist/compiled/@next/font';
import { Inter } from 'next/font/google';
import colors from '@/src/shared/config/theme/colors';

const inter: NextFont = Inter({ subsets: ['latin'] });

const antDesign: ThemeConfig = {
  token: {
    // inter.style.fontFamily looks like this: '__Inter_d65c78', '__Inter_Fallback_d65c78', ...
    fontFamily: `${inter.style.fontFamily}, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,

    // Base colors
    colorPrimary: colors.primary['500'],
    colorLink: colors.link['500'],
    colorSuccess: colors.success['500'],
    colorWarning: colors.warning['500'],
    colorError: colors.error['500'],
    colorTextBase: colors.textBase['500'],
    colorBgBase: colors.bgBase['500'],
  },
  components: {
    Button: {
      defaultShadow: 'none',
      primaryShadow: 'none',
      dangerShadow: 'none',

      borderRadiusSM: 16,
      borderRadius: 18,
      borderRadiusLG: 20,

      controlHeightSM: 32,
      controlHeight: 36,
      controlHeightLG: 40,

      fontWeight: 500,
    },

    Menu: {
      itemBg: 'transparent',
      itemSelectedBg: colors.primary['700'] + '1A', // TODO: I don't like this either, will find helper function to apply alpha
      itemHoverBg: colors.primary['700'] + '1A',
      itemActiveBg: 'transparent',
      padding: 12,
      itemMarginInline: 0,
      // borderRadius: 20,
      // borderRadiusLG: 30,
    },

    Input: {
      activeBg: colors.bgBase['500'],
      hoverBg: colors.bgBase['500'],
      activeBorderColor: colors.primary['500'],
      hoverBorderColor: colors.primary['500'] + '80',
      colorBorder: colors.bgBase['100'] + '1A',
      activeShadow: 'none',

      borderRadiusSM: 16,
      borderRadius: 18,
      borderRadiusLG: 20,

      controlHeightSM: 32,
      controlHeight: 36,
      controlHeightLG: 40,
    },

    Segmented: {
      trackBg: colors.bgBase['300'],
      itemSelectedBg: colors.bgBase['350'],
      borderRadiusSM: 12,
      borderRadius: 18,
      borderRadiusLG: 20,
    },

    Modal: {
      contentBg: colors.bgBase['350'],
      headerBg: 'transparent',
    },

    Divider: {
      colorSplit: colors.divider,
    },

    Form: {
      labelColor: colors.textBase['700'],
      fontSize: 14,
      itemMarginBottom: 0,
      verticalLabelPadding: '0 0 2px 0',
    },

    Dropdown: {
      colorBgElevated: colors.bgBase['350'],
    },

    Select: {
      colorBgContainer: colors.bgBase['350'],
      colorBgElevated: colors.bgBase['350'],
      optionSelectedBg: '#000000',
      colorBorder: colors.bgBase['100'] + '1A',
      controlOutline: 'transparent',

      borderRadiusSM: 16,
      borderRadius: 18,
      borderRadiusLG: 20,
    },

    List: {
      colorSplit: colors.divider,
    },

    Tooltip: {
      colorBgSpotlight: colors.divider,
    },

    Badge: {
      statusSize: 8,
    },

    Table: {
      colorBgContainer: 'transparent',
      borderColor: '#FFFFFF' + '0D', // 5% opacity
      headerSplitColor: '#FFFFFF' + '0D', // 5% opacity
    },
  },
};

export default antDesign;
