'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="Lock.tsx">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.0021 21.0036H6.9979C5.89287 21.0036 4.99707 20.1078 4.99707 19.0028V11.9999C4.99707 10.8948 5.89287 9.99902 6.9979 9.99902H17.0021C18.1071 9.99902 19.0029 10.8948 19.0029 11.9999V19.0028C19.0029 20.1078 18.1071 21.0036 17.0021 21.0036Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Lock.tsx" />
    <path d="M7.99854 9.99901V6.99776C7.99854 4.7877 9.79014 2.99609 12.0002 2.99609C14.2103 2.99609 16.0019 4.7877 16.0019 6.99776V9.99901" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Lock.tsx" />
    <path d="M10.3156 15.4322C10.2775 15.4322 10.2466 15.4631 10.2466 15.5012C10.2466 15.5393 10.2775 15.5702 10.3156 15.5702C10.3538 15.5702 10.3847 15.5393 10.3847 15.5012C10.3847 15.4829 10.3774 15.4653 10.3644 15.4524C10.3515 15.4394 10.3339 15.4322 10.3156 15.4322" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Lock.tsx" />
    <path d="M13.6157 15.5012C13.6157 15.5393 13.6467 15.5702 13.6848 15.5702C13.7229 15.5702 13.7538 15.5393 13.7538 15.5012C13.7538 15.4631 13.7229 15.4322 13.6848 15.4322C13.6665 15.4322 13.6489 15.4394 13.636 15.4524C13.623 15.4653 13.6157 15.4829 13.6157 15.5012" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Lock.tsx" />
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="Lock.tsx" />;
};
export default CustomIcon;