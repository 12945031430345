'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg width="1em" height="1em" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="Sprinkles.tsx">
    <path d="M6.5 17.5V21.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Sprinkles.tsx" />
    <path d="M4.5 19.5H8.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Sprinkles.tsx" />
    <path d="M17.5 3.5V7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Sprinkles.tsx" />
    <path d="M15.5 5.5H19.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Sprinkles.tsx" />
    <path d="M7 5.5C7 7.433 8.567 9 10.5 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Sprinkles.tsx" />
    <path d="M10.5 9C8.567 9 7 10.567 7 12.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Sprinkles.tsx" />
    <path d="M7 12.5C7 10.567 5.433 9 3.5 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Sprinkles.tsx" />
    <path d="M3.5 9C5.433 9 7 7.433 7 5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Sprinkles.tsx" />
    <path d="M12.1089 16.278C14.7019 16.278 16.8039 14.176 16.8039 11.583" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Sprinkles.tsx" />
    <path d="M16.8049 11.583C16.8049 14.176 18.9069 16.278 21.4999 16.278" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Sprinkles.tsx" />
    <path d="M21.4999 16.278C18.9069 16.278 16.8049 18.38 16.8049 20.973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Sprinkles.tsx" />
    <path d="M16.8051 20.973C16.8051 18.38 14.7031 16.278 12.1101 16.278" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Sprinkles.tsx" />
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="Sprinkles.tsx" />;
};
export default CustomIcon;