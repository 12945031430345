'use client';

import { UserModel } from '@project-adonis/validators';
import Mixpanel from 'mixpanel-browser';
import { useCallback, useEffect, useMemo } from 'react';
import getQueryClient from '@/src/shared/config/queryClient';
import { usePrivacyStore } from '@/src/shared/hooks/stores/usePrivacyStore';
import { CookieStoringCategoryEnum } from '@/src/shared/types/enums/cookie-storing-category.enum';

export enum MixpanelEventEnum {
  START_CHARACTER_CREATION = 'Start Character Creation',
  CHOSE_CHARACTER_FIELD = 'Chose Character Field',
  SIGN_UP_REQUEST_AFTER_CHARACTER_CREATION = 'Sign Up Request After Character Creation',
  SIGN_UP = 'Sign Up',
  SIGN_IN = 'Sign In',
  SIGN_UP_OR_SIGN_IN_WITH_OAUTH = 'Sign Up or Sign In with OAuth',
  SEND_FIRST_MESSAGE = 'Send First Message',
  REACHED_MESSAGE_LIMIT = 'Reached Message Limit',
  REACHED_CHARACTER_CREATION_LIMIT = 'Reached Character Creation Limit',
  CHARACTER_CREATION_COMPLETED = 'Character Creation Completed',
  STARTED_SUBSCRIPTION_PURCHASE = 'Started Subscription Purchase',
  COMPLETED_SUBSCRIPTION_PURCHASE = 'Completed Subscription Purchase',
  ABANDONED_SUBSCRIPTION_PURCHASE = 'Abandoned Subscription Purchase',
  USER_CHOSE_ACCOUNT_DELETION_REASON = 'User Chose Account Deletion Reason',
  USER_CHOSE_SUBSCRIPTION_CANCELLATION_REASON = 'User Chose Subscription Cancellation Reason',
}

interface MixpanelHookResponse {
  initialize: () => void;
  setUser: (user?: UserModel) => void;
  trackEvent: (event: MixpanelEventEnum, data?: object) => void;
}

let isMixpanelInitialized = false;

const useMixpanel = (): MixpanelHookResponse => {
  const { categories } = usePrivacyStore();
  const isEnabled = categories?.[CookieStoringCategoryEnum.ANALYTIC] ?? false;
  const queryClient = getQueryClient();

  useEffect(() => {
    if (!isEnabled && isMixpanelInitialized) {
      Mixpanel.reset();
      Mixpanel.opt_out_tracking();
      isMixpanelInitialized = false;
    }
  }, [isEnabled]);

  const initialize = useCallback((): void => {
    if (isEnabled && !isMixpanelInitialized) {
      Mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ?? '', {
        debug: false,
        track_pageview: true,
        persistence: 'cookie',
      });
      isMixpanelInitialized = true;
    }
  }, [isEnabled]);

  const setUser = useCallback(
    (user?: UserModel): void => {
      if (!isEnabled) return;
      initialize();

      const userFromQuery = queryClient.getQueryData<UserModel>([
        '/api/users/me',
      ]);

      if (!user && userFromQuery) {
        user = userFromQuery;
      }

      if (!user) return;

      Mixpanel.identify(user.id.toString());

      Mixpanel.people.set({
        $name:
          user.firstName && user.firstName
            ? `${user.firstName} ${user.lastName}`
            : 'Unknown',
        $email: user.email,
      });
    },
    [initialize, isEnabled, queryClient],
  );

  const trackEvent = useCallback(
    (event: MixpanelEventEnum, data?: object): void => {
      if (!isEnabled) return;
      initialize();

      Mixpanel.track(event as string, data);
    },
    [initialize, isEnabled],
  );

  return useMemo(() => {
    return {
      initialize,
      setUser,
      trackEvent,
    };
  }, [initialize, setUser, trackEvent]);
};

export default useMixpanel;
