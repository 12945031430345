'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg width="1em" height="1em" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="Google.tsx">
    <g id="flat-color-icons:google" data-sentry-element="g" data-sentry-source-file="Google.tsx">
      <path id="Vector" d="M22.0555 10.0415H21.25V10H12.25V14H17.9015C17.077 16.3285 14.8615 18 12.25 18C8.9365 18 6.25 15.3135 6.25 12C6.25 8.6865 8.9365 6 12.25 6C13.7795 6 15.171 6.577 16.2305 7.5195L19.059 4.691C17.273 3.0265 14.884 2 12.25 2C6.7275 2 2.25 6.4775 2.25 12C2.25 17.5225 6.7275 22 12.25 22C17.7725 22 22.25 17.5225 22.25 12C22.25 11.3295 22.181 10.675 22.0555 10.0415Z" fill="#FFC107" data-sentry-element="path" data-sentry-source-file="Google.tsx" />
      <path id="Vector_2" d="M3.40283 7.3455L6.68833 9.755C7.57733 7.554 9.73033 6 12.2498 6C13.7793 6 15.1708 6.577 16.2303 7.5195L19.0588 4.691C17.2728 3.0265 14.8838 2 12.2498 2C8.40883 2 5.07783 4.1685 3.40283 7.3455Z" fill="#FF3D00" data-sentry-element="path" data-sentry-source-file="Google.tsx" />
      <path id="Vector_3" d="M12.2502 22.0003C14.8332 22.0003 17.1802 21.0118 18.9547 19.4043L15.8597 16.7853C14.8219 17.5745 13.5539 18.0014 12.2502 18.0003C9.64916 18.0003 7.44066 16.3418 6.60866 14.0273L3.34766 16.5398C5.00266 19.7783 8.36366 22.0003 12.2502 22.0003Z" fill="#4CAF50" data-sentry-element="path" data-sentry-source-file="Google.tsx" />
      <path id="Vector_4" d="M22.0555 10.0415H21.25V10H12.25V14H17.9015C17.5071 15.1082 16.7967 16.0766 15.858 16.7855L15.8595 16.7845L18.9545 19.4035C18.7355 19.6025 22.25 17 22.25 12C22.25 11.3295 22.181 10.675 22.0555 10.0415Z" fill="#1976D2" data-sentry-element="path" data-sentry-source-file="Google.tsx" />
    </g>
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="Google.tsx" />;
};
export default CustomIcon;