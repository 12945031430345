'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="User.tsx">
    <path fillRule="evenodd" clipRule="evenodd" d="M33.3332 33.3333V32.0833C33.3332 28.1713 30.1619 25 26.2498 25H13.7498C9.83782 25 6.6665 28.1713 6.6665 32.0833V33.3333" data-sentry-element="path" data-sentry-source-file="User.tsx" />
    <path d="M33.3332 33.3333V32.0833C33.3332 28.1713 30.1619 25 26.2498 25H13.7498C9.83782 25 6.6665 28.1713 6.6665 32.0833V33.3333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="User.tsx" />
    <ellipse cx="20.0002" cy="11.6667" rx="6.66667" ry="6.66667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="ellipse" data-sentry-source-file="User.tsx" />
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="User.tsx" />;
};
export default CustomIcon;