import { AuthModeEnum } from '@/src/shared/types/enums/auth-mode.enum';

export const HOME = '/';
export const CHAT = '/chat';
export const CHAT_ID = '/chat/:id/:slug';
export const CREATE_CHARACTER = '/create-character';
export const GENERATE_IMAGE = '/generate-image';
export const GALLERY = '/gallery';
export const GALLERY_ID = '/gallery/:id/:slug';
export const SIGN_IN = `?authAction=${AuthModeEnum.SignIn}`;
export const SIGN_UP = `?authAction=${AuthModeEnum.SignUp}`;
export const UPGRADE_TO_PREMIUM = '?upgradeToPremium=:type';
export const PASSWORD_RECOVERY = `?authAction=${AuthModeEnum.PasswordRecovery}`;
export const PASSWORD_RESET = `?authAction=${AuthModeEnum.PasswordReset}`;
export const PRICING = '/pricing';
export const CHARACTER_ID = '/character/:id/:slug';
export const AUTH_CALLBACK = '/auth/callback';
export const MY_CHARACTERS = '/my-characters';
export const TERMS_OF_SERVICE = '/terms-of-service';
export const PRIVACY_POLICY = '/privacy-policy';
export const BLOCKED_CONTENT_POLICY = '/blocked-content-policy';
export const UNDERAGE_POLICY = '/underage-policy';
export const PAYMENTS_REDIRECT_CHECKOUT = '/payments/redirect/checkout';
export const PAYMENTS_REDIRECT_SUCCESS = '/payments/redirect/success';
export const PAYMENTS_REDIRECT_MANAGE = '/payments/redirect/manage';
export const PAYMENTS_SUCCESS = '/payments/success';

export const SETTINGS = '/settings';
export const SETTINGS_PROFILE = '/settings/profile';
export const SETTINGS_CHANGE_PASSWORD = '/settings/change-password';
export const SETTINGS_BILLING = '/settings/billing';
export const SETTINGS_COOKIE_MANAGEMENT = '/settings/cookie-management';
export const SETTINGS_DELETE_ACCOUNT = '/settings/delete-account';

export const PUBLIC_ROUTES = [
  HOME,
  SIGN_UP,
  SIGN_IN,
  PASSWORD_RESET,
  PASSWORD_RECOVERY,
  PRICING,
  CREATE_CHARACTER,
  AUTH_CALLBACK,
  CHARACTER_ID,
  CHAT,
  CHAT_ID,
  MY_CHARACTERS,
  GALLERY,
  TERMS_OF_SERVICE,
  PRIVACY_POLICY,
  BLOCKED_CONTENT_POLICY,
  UNDERAGE_POLICY,
  PAYMENTS_REDIRECT_CHECKOUT,
  PAYMENTS_REDIRECT_SUCCESS,
];

export const NO_SFW_ROUTES = [CREATE_CHARACTER, GENERATE_IMAGE, MY_CHARACTERS];

export const MAP_NOSFW_ROUTES_TO_SFW_ROUTES: Record<string, string> = {
  [CREATE_CHARACTER]: CHAT,
};

export const PREMIUM_RESTRICTED_ROUTES = [UPGRADE_TO_PREMIUM, PRICING];
