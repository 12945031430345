class HttpError extends Error {
  data: unknown;
  status: number;

  constructor({
    message,
    data,
    status,
  }: {
    message: string;
    data: unknown;
    status: number;
  }) {
    super(message);
    this.data = data;
    this.status = status;
  }

  toResponse(): Response {
    return new Response(JSON.stringify(this.data), {
      status: this.status,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}

export default HttpError;
