import { TraitsUserInputModel } from '@project-adonis/validators';
import { avatarImageSrcs } from '@/src/app/settings/profile/AvatarSelectorModal/avatars';
import { TraitTypeEnum } from '@/src/shared/types/enums/trait-type-enum';

export const getTraitNameByType = (
  traits: TraitsUserInputModel[],
  type: TraitTypeEnum,
): string | undefined => {
  return (
    traits.find((trait) => (trait.type as unknown as TraitTypeEnum) === type)
      ?.name ?? undefined
  );
};

export const matchRoute = (route: string, currentPath: string): boolean => {
  // Split the current path into path and query parts
  const [pathWithoutQuery, query] = currentPath.split('?');

  // Check if the route is a query parameter
  if (route.startsWith('?')) {
    return query === route.substring(1); // Compare the query part directly
  }

  // Convert the route pattern into a regex pattern
  const routePattern = route.replace(/:[^\s/]+/g, '([^/]+)');

  // Create a regex from the pattern and match it against the current path without the query string
  const regex = new RegExp(`^${routePattern}$`);
  return regex.test(pathWithoutQuery);
};

// Find the Least Common Multiple (LCM) of an array of numbers
export const getLCM = ({
  multipliers,
  min = 1,
  max = 100,
}: {
  multipliers: number[];
  min?: number;
  max?: number;
}): number => {
  const gcd = (a: number, b: number): number => (b === 0 ? a : gcd(b, a % b));
  const lcmOfTwo = (a: number, b: number): number => (a * b) / gcd(a, b);

  const lcm = multipliers.reduce((acc, num) => lcmOfTwo(acc, num), 1);

  if (lcm > max) return max;

  const multiplier = Math.ceil(min / lcm);
  const result = lcm * multiplier;

  return result > max ? max : result;
};

export const getUserAvatarSrc = (avatarKey?: number): string | undefined => {
  return avatarImageSrcs.find(({ key }) => key === avatarKey)?.src ?? undefined;
};
