import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Button, Input, Form } from 'antd';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import { ComponentType, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PasswordRecoverySchema } from '@/src/shared/components/AuthModal/PasswordRecoveryForm/schemas/password-recovery.schema';
import EmailIcon from '@/src/shared/components/Icons/Email';
import * as routes from '@/src/shared/constants/routes';
import { usePasswordRecoveryMutation } from '@/src/shared/hooks/queries/usePasswordRecoveryMutation';
import { useNotificationStore } from '@/src/shared/hooks/stores/useNotificationStore';
interface Props {
  onModalClose: () => void;
}
const PasswordRecoveryForm: ComponentType<Props> = ({
  onModalClose
}: Props) => {
  const t = useTranslations();
  const {
    notificationApi
  } = useNotificationStore();
  const {
    mutateAsync,
    isPending
  } = usePasswordRecoveryMutation();
  const {
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<PasswordRecoverySchema>({
    resolver: classValidatorResolver(PasswordRecoverySchema),
    mode: 'onBlur'
  });
  const onFinish = useCallback(async (values: PasswordRecoverySchema) => {
    try {
      await mutateAsync(values);
      notificationApi?.success({
        message: 'Password recovery email sent',
        description: "We've sent you an email with instructions to recover your password",
        duration: 6
      });
      onModalClose();
    } catch (e) {
      const error = e as Error;
      notificationApi?.error({
        message: error.name,
        description: error?.message
      });
    }
  }, [notificationApi, onModalClose, mutateAsync]);
  return <form onSubmit={handleSubmit(onFinish)} data-sentry-component="PasswordRecoveryForm" data-sentry-source-file="PasswordRecoveryForm.tsx">
      <h1 className="text-xl font-medium mb-3">
        {t('passwordRecoveryForm.title')}
      </h1>
      <div className="flex flex-col gap-2 mb-2">
        <Controller render={({
        field
      }) => {
        return <Form.Item validateStatus={errors.email ? 'error' : undefined} help={errors.email?.message}>
                <Input type="email" size="large" placeholder={t('common.email')} prefix={<EmailIcon className="text-xl" />} disabled={isPending} {...field} />
              </Form.Item>;
      }} name="email" control={control} data-sentry-element="Controller" data-sentry-source-file="PasswordRecoveryForm.tsx" />
      </div>
      <Link href={routes.SIGN_IN} className="text-sm text-textBase-800 mb-3 inline-block" data-sentry-element="Link" data-sentry-source-file="PasswordRecoveryForm.tsx">
        {t('passwordRecoveryForm.rememberedPassword')}
      </Link>
      <Button htmlType="submit" type="primary" size="large" className="w-full" loading={isPending} data-sentry-element="Button" data-sentry-source-file="PasswordRecoveryForm.tsx">
        {t('passwordRecoveryForm.button')}
      </Button>
    </form>;
};
export default PasswordRecoveryForm;