'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg width="1em" height="1em" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="Chat.tsx">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.166 2.50001C9.779 2.49501 6.656 4.32801 5.009 7.28801C3.362 10.248 3.451 13.869 5.241 16.744L3.5 21.5L8.255 19.759C11.619 21.844 15.933 21.58 19.017 19.099C22.101 16.618 23.284 12.461 21.968 8.72801C20.653 4.99401 17.124 2.49801 13.166 2.50001V2.50001Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Chat.tsx" />
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="Chat.tsx" />;
};
export default CustomIcon;