import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Button, Input, Form } from 'antd';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import { useRouter } from 'nextjs-toploader/app';
import { ComponentType, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SignInSchema } from '@/src/shared/components/AuthModal/SignInForm/schemas/sign-in.schema';
import EmailIcon from '@/src/shared/components/Icons/Email';
import LockIcon from '@/src/shared/components/Icons/Lock';
import * as routes from '@/src/shared/constants/routes';
import { useSignInMutation } from '@/src/shared/hooks/queries/useSignInMutation';
import { useNotificationStore } from '@/src/shared/hooks/stores/useNotificationStore';
import useMixpanel, { MixpanelEventEnum } from '@/src/shared/hooks/useMixpanel';
interface Props {
  onModalClose: () => void;
}
const SignInForm: ComponentType<Props> = ({
  onModalClose
}: Props) => {
  const t = useTranslations();
  const router = useRouter();
  const {
    notificationApi,
    messageApi
  } = useNotificationStore();
  const {
    mutateAsync,
    isPending
  } = useSignInMutation();
  const {
    setUser,
    trackEvent
  } = useMixpanel();
  const {
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<SignInSchema>({
    resolver: classValidatorResolver(SignInSchema),
    mode: 'onBlur'
  });
  const onFinish = useCallback(async (values: SignInSchema) => {
    try {
      await mutateAsync(values);
      messageApi?.success({
        content: "You've successfully logged in"
      });
      trackEvent(MixpanelEventEnum.SIGN_IN);
      setUser();
      onModalClose();
      router.refresh();
    } catch (e) {
      notificationApi?.error({
        message: 'Invalid email or password'
      });
    }
  }, [mutateAsync, messageApi, trackEvent, setUser, onModalClose, router, notificationApi]);
  return <form onSubmit={handleSubmit(onFinish)} data-sentry-component="SignInForm" data-sentry-source-file="SignInForm.tsx">
      <div className="flex flex-col gap-2 mb-2">
        <Controller render={({
        field
      }) => {
        return <Form.Item validateStatus={errors.email ? 'error' : undefined} help={errors.email?.message}>
                <Input type="email" size="large" placeholder={t('common.email')} prefix={<EmailIcon className="text-xl" />} disabled={isPending} {...field} />
              </Form.Item>;
      }} name="email" control={control} data-sentry-element="Controller" data-sentry-source-file="SignInForm.tsx" />

        <Controller render={({
        field
      }) => {
        return <Form.Item validateStatus={errors.password ? 'error' : undefined} help={errors.password?.message}>
                <Input.Password size="large" placeholder={t('common.password')} visibilityToggle prefix={<LockIcon className="text-xl" />} disabled={isPending} {...field} />
              </Form.Item>;
      }} name="password" control={control} data-sentry-element="Controller" data-sentry-source-file="SignInForm.tsx" />
      </div>
      <Link href={routes.PASSWORD_RECOVERY} className="text-sm text-textBase-800 mb-3 inline-block" data-sentry-element="Link" data-sentry-source-file="SignInForm.tsx">
        {t('signInForm.forgotPassword')}
      </Link>
      <Button htmlType="submit" type="primary" size="large" className="w-full" loading={isPending} data-sentry-element="Button" data-sentry-source-file="SignInForm.tsx">
        {t('signInForm.button')}
      </Button>
    </form>;
};
export default SignInForm;