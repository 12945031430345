'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 16 16" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="Instagram.tsx">
    <linearGradient id="a" x1="1.464" x2="14.536" y1="14.536" y2="1.464" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Instagram.tsx">
      <stop offset="0" stopColor="#FFC107" data-sentry-element="stop" data-sentry-source-file="Instagram.tsx"></stop>
      <stop offset=".507" stopColor="#F44336" data-sentry-element="stop" data-sentry-source-file="Instagram.tsx"></stop>
      <stop offset=".99" stopColor="#9C27B0" data-sentry-element="stop" data-sentry-source-file="Instagram.tsx"></stop>
    </linearGradient>
    <path fill="url(#a)" d="M11 0H5a5 5 0 0 0-5 5v6a5 5 0 0 0 5 5h6a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5zm3.5 11c0 1.93-1.57 3.5-3.5 3.5H5c-1.93 0-3.5-1.57-3.5-3.5V5c0-1.93 1.57-3.5 3.5-3.5h6c1.93 0 3.5 1.57 3.5 3.5v6z" data-sentry-element="path" data-sentry-source-file="Instagram.tsx"></path>
    <linearGradient id="b" x1="5.172" x2="10.828" y1="10.828" y2="5.172" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Instagram.tsx">
      <stop offset="0" stopColor="#FFC107" data-sentry-element="stop" data-sentry-source-file="Instagram.tsx"></stop>
      <stop offset=".507" stopColor="#F44336" data-sentry-element="stop" data-sentry-source-file="Instagram.tsx"></stop>
      <stop offset=".99" stopColor="#9C27B0" data-sentry-element="stop" data-sentry-source-file="Instagram.tsx"></stop>
    </linearGradient>
    <path fill="url(#b)" d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6.5A2.503 2.503 0 0 1 5.5 8c0-1.379 1.122-2.5 2.5-2.5s2.5 1.121 2.5 2.5c0 1.378-1.122 2.5-2.5 2.5z" data-sentry-element="path" data-sentry-source-file="Instagram.tsx"></path>
    <linearGradient id="c" x1="11.923" x2="12.677" y1="4.077" y2="3.323" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Instagram.tsx">
      <stop offset="0" stopColor="#FFC107" data-sentry-element="stop" data-sentry-source-file="Instagram.tsx"></stop>
      <stop offset=".507" stopColor="#F44336" data-sentry-element="stop" data-sentry-source-file="Instagram.tsx"></stop>
      <stop offset=".99" stopColor="#9C27B0" data-sentry-element="stop" data-sentry-source-file="Instagram.tsx"></stop>
    </linearGradient>
    <circle cx="12.3" cy="3.7" r=".533" fill="url(#c)" data-sentry-element="circle" data-sentry-source-file="Instagram.tsx"></circle>
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="Instagram.tsx" />;
};
export default CustomIcon;