'use client';

import { ComponentType } from 'react';
import Icon, { IconProps } from '@/src/shared/components/Icons/Icon';
const svg: ComponentType = () => <svg width="1em" height="1em" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="svg" data-sentry-source-file="Discord.tsx">
    <g clipPath="url(#clip0_1_1039)" data-sentry-element="g" data-sentry-source-file="Discord.tsx">
      <path d="M27.857 2.00165C25.7584 1.05427 23.5383 0.381126 21.2552 0C20.9709 0.49604 20.6386 1.16322 20.4095 1.69399C17.948 1.33684 15.5091 1.33684 13.0929 1.69399C12.8639 1.16334 12.5241 0.49604 12.2371 0C9.95185 0.381292 7.72981 1.05616 5.63024 2.00659C1.45224 8.09813 0.319616 14.0382 0.885866 19.8942C3.65699 21.8908 6.34249 23.1037 8.98274 23.8974C9.63895 23.0268 10.219 22.1051 10.7169 21.1417C9.7689 20.7935 8.85502 20.3644 7.98612 19.8595C8.21476 19.6959 8.43804 19.5255 8.65562 19.3485C13.9209 21.7246 19.6419 21.7246 24.8444 19.3485C25.0629 19.5243 25.2861 19.6947 25.5137 19.8595C24.6435 20.3658 23.7279 20.7957 22.778 21.1442C23.2787 22.1115 23.8577 23.0341 24.5121 23.8998C27.1549 23.1062 29.8429 21.8933 32.614 19.8942C33.2785 13.1057 31.479 7.22014 27.857 2.00165ZM11.4342 16.2929C9.85362 16.2929 8.55737 14.8691 8.55737 13.1355C8.55737 11.4018 9.82599 9.97568 11.4342 9.97568C13.0425 9.97568 14.3387 11.3993 14.3111 13.1355C14.3136 14.8691 13.0426 16.2929 11.4342 16.2929ZM22.0656 16.2929C20.485 16.2929 19.1889 14.8691 19.1889 13.1355C19.1889 11.4018 20.4574 9.97568 22.0656 9.97568C23.674 9.97568 24.9701 11.3993 24.9425 13.1355C24.9425 14.8691 23.674 16.2929 22.0656 16.2929Z" fill="#5865F2" data-sentry-element="path" data-sentry-source-file="Discord.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Discord.tsx">
      <clipPath id="clip0_1_1039" data-sentry-element="clipPath" data-sentry-source-file="Discord.tsx">
        <rect width="32" height="24" fill="white" transform="translate(0.75)" data-sentry-element="rect" data-sentry-source-file="Discord.tsx" />
      </clipPath>
    </defs>
  </svg>;
const CustomIcon: ComponentType<IconProps> = props => {
  return <Icon {...props} svg={svg} data-sentry-element="Icon" data-sentry-component="CustomIcon" data-sentry-source-file="Discord.tsx" />;
};
export default CustomIcon;