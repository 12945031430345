class SearchParamManager {
  // Add or update search parameters
  static addOrUpdate(params: Record<string, string>): void {
    const paramsObj = new URLSearchParams(window.location.search);

    Object.keys(params).forEach((param) => {
      paramsObj.set(param, params[param]);
    });

    window.history.replaceState({}, document.title, `?${paramsObj.toString()}`);
  }

  // Delete search parameters
  static delete(params: string[]): void {
    const paramsObj = new URLSearchParams(window.location.search);

    params.forEach((param) => {
      paramsObj.delete(param);
    });

    window.history.replaceState({}, document.title, `?${paramsObj.toString()}`);
  }

  // Check if a search parameter exists
  static has(param: string): boolean {
    const paramsObj = new URLSearchParams(window.location.search);
    return paramsObj.has(param);
  }

  // Replace all search parameters with new ones
  static replace(params: Record<string, string>): void {
    const paramsObj = new URLSearchParams();

    Object.keys(params).forEach((param) => {
      paramsObj.set(param, params[param]);
    });

    window.history.replaceState({}, document.title, `?${paramsObj.toString()}`);
  }

  // Get the value of a specific search parameter
  static get(param: string): string | null {
    const paramsObj = new URLSearchParams(window.location.search);
    return paramsObj.get(param);
  }

  // Get all search parameters as an object
  static getAll(): Record<string, string> {
    const paramsObj = new URLSearchParams(window.location.search);
    const params: Record<string, string> = {};

    paramsObj.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }
}

export default SearchParamManager;
