'use client';

import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { signIn } from 'next-auth/react';
import { SignInInterface } from '@/src/shared/components/AuthModal/SignInForm/types/sign-in.interface';

export const useSignInMutation = (): UseMutationResult<
  void,
  Error,
  SignInInterface
> => {
  return useMutation({
    mutationFn: async (values) => {
      const result = await signIn('CREDENTIALS', {
        redirect: false,
        email: values.email,
        password: values.password,
      });

      if (result?.error) {
        throw new Error(result.error);
      }
    },
  });
};
